/* You can add global styles to this file, and also import other style files */

@import "./app/styles/tailwind.scss";


/* For use in _color-definitions.scss */
$palette-primary:
rgb(231, 232, 234)
rgb(196, 198, 203)
rgb(157, 161, 169)
rgb(118, 123, 134)
rgb(88, 94, 108)
rgb(59, 66, 82)
rgb(53, 60, 75)
rgb(45, 51, 65)
rgb(38, 43, 56)
rgb(25, 29, 40)
rgb(110, 154, 255)
rgb(59, 119, 255)
rgb(8, 84, 255)
rgb(0, 73, 237)
;

$palette-primary-50: nth($palette-primary, 1);
$palette-primary-100: nth($palette-primary, 2);
$palette-primary-200: nth($palette-primary, 3);
$palette-primary-300: nth($palette-primary, 4);
$palette-primary-400: nth($palette-primary, 5);
$palette-primary-500: nth($palette-primary, 6);
$palette-primary-600: nth($palette-primary, 7);
$palette-primary-700: nth($palette-primary, 8);
$palette-primary-800: nth($palette-primary, 9);
$palette-primary-900: nth($palette-primary, 10);
$palette-primary-A100: nth($palette-primary, 11);
$palette-primary-A200: nth($palette-primary, 12);
$palette-primary-A400: nth($palette-primary, 13);
$palette-primary-A700: nth($palette-primary, 14);

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e7e8ea,
    100 : #c4c6cb,
    200 : #9da1a9,
    300 : #767b86,
    400 : #585e6c,
    500 : #3b4252,
    600 : #353c4b,
    700 : #2d3341,
    800 : #262b38,
    900 : #191d28,
    A100 : #6e9aff,
    A200 : #3b77ff,
    A400 : #0854ff,
    A700 : #0049ed,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


/* For use in _color-definitions.scss */
$palette-accent:
rgb(243, 241, 238)
rgb(225, 220, 213)
rgb(205, 197, 186)
rgb(184, 174, 158)
rgb(169, 156, 137)
rgb(154, 139, 116)
rgb(146, 131, 108)
rgb(136, 120, 97)
rgb(126, 110, 87)
rgb(108, 91, 68)
rgb(255, 231, 200)
rgb(255, 208, 149)
rgb(255, 186, 98)
rgb(255, 175, 72)
;

$palette-accent-50: nth($palette-accent, 1);
$palette-accent-100: nth($palette-accent, 2);
$palette-accent-200: nth($palette-accent, 3);
$palette-accent-300: nth($palette-accent, 4);
$palette-accent-400: nth($palette-accent, 5);
$palette-accent-500: nth($palette-accent, 6);
$palette-accent-600: nth($palette-accent, 7);
$palette-accent-700: nth($palette-accent, 8);
$palette-accent-800: nth($palette-accent, 9);
$palette-accent-900: nth($palette-accent, 10);
$palette-accent-A100: nth($palette-accent, 11);
$palette-accent-A200: nth($palette-accent, 12);
$palette-accent-A400: nth($palette-accent, 13);
$palette-accent-A700: nth($palette-accent, 14);


/* For use in src/lib/core/theming/_palette.scss */
$md-accent: (
    50 : #f3f1ee,
    100 : #e1dcd5,
    200 : #cdc5ba,
    300 : #b8ae9e,
    400 : #a99c89,
    500 : #9a8b74,
    600 : #92836c,
    700 : #887861,
    800 : #7e6e57,
    900 : #6c5b44,
    A100 : #ffe7c8,
    A200 : #ffd095,
    A400 : #ffba62,
    A700 : #ffaf48,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// styles

.mat-toolbar-spacer {
  flex: 1 1 auto;
}

.bg-primary {
  background-color: $palette-primary-500;
}

.bg-accent {
  background-color: $palette-accent-500;
}


.center-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.border-gray-1 {
  border: 1px solid lightgray;
}

.background-white, .bg-white {
  background-color: white;
}


.input-text-1 {
  @apply px-3 py-3 border-gray-1 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full mb-3;
}


.card-box {
  @apply bg-white border border-gray-300 shadow-md;
}

.card-box-1 {
  @apply bg-white p-5 shadow-md border-gray-1 mb-4;
}

.main-app-page-container {
  min-height: 100vh;
}

.btn-disabled-1 {
  @apply bg-gray-300 hover:bg-gray-200 text-[color:white] cursor-not-allowed font-bold py-2 px-4 rounded;
}

.btn-primary-1 {
  @apply bg-primary hover:bg-accent text-[color:white] cursor-pointer font-bold py-2 px-4 rounded;
}

.btn-success-1 {
  @apply bg-green-500 hover:bg-green-700 text-[color:white] cursor-pointer font-bold py-2 px-4 rounded;
}

.btn-danger-1 {
  @apply bg-red-500 hover:bg-red-700 text-[color:white] cursor-pointer font-bold py-2 px-4 rounded;
}

.btn-warning-1 {
  @apply bg-yellow-500 hover:bg-yellow-700 text-[color:white] cursor-pointer font-bold py-2 px-4 rounded;
}

.btn-info-1 {
  @apply bg-blue-500 hover:bg-blue-700 text-[color:white] cursor-pointer font-bold py-2 px-4 rounded;
}

.plain-links-list {
  @apply my-4 pl-4;

  li {
    list-style: disc;
    a {

    }

    a.active {
      @apply font-bold text-blue-500 text-lg;
    }
  }
}


.box-list {
  border-right: 1px solid lightgrey;
}

.box-container {
  border: 1px solid lightgrey;
}

.box-item {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .box-item-inner {
    overflow: hidden;

    .user-icon-square {
      float: left;
      margin-right: 10px;
    }
  }
}

.box-item:hover {
  background: #fbfbfb;
  cursor: pointer;
}

.box-item:last-child {
  border-bottom: none;
}



.messagings-list {
  border-right: 1px solid lightgrey;
}

.messagings-container {
  border: 1px solid lightgrey;
}

.messagings-item {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .messagings-item-inner {
    overflow: hidden;

    .user-icon-square {
      float: left;
      margin-right: 10px;
    }
  }
}

.messagings-item:hover {
  background: #fbfbfb;
  cursor: pointer;
}

.messages-item {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .messages-item-inner {
    overflow: hidden;

    .user-icon-square {
      float: left;
      margin-right: 10px;
    }

    .message-text {
      margin-left: 60px;
      padding: 5px;
      white-space: pre-wrap;
    }
  }
}

.messages-item:last-child,
.messagings-item:last-child {
  border-bottom: none;
}