/* You can add global styles to this file, and also import other style files */

// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";
// @import "tailwindcss/variants";
// @import "tailwindcss/screens";



body {
  background-color: #f2f2f2;
}

ul, ol {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

a {
  color: #62a5ed
}

.container.gutter {
  max-width: 95%;
}

.polaroid {
  border: 1px solid lightgrey;
  padding: 3px;
  box-shadow: 0px 3px 7px lightgray;
}

